<template>
  <div class="user">
    <pdf
      ref="pdf"
      :src="url"
      v-for="i in numPages"
      :key="i"
      :page="i"></pdf>
  </div>
</template>

<script>
  import pdf from 'vue-pdf'
  export default{
     components:{
        pdf
     },
     data(){
          return {
              url:this.$route.query.type === 'privacy' ? "https://xnpfapp.oss-cn-shenzhen.aliyuncs.com/pdf/Privacyagreement.pdf" : 'https://xnpfapp.oss-cn-shenzhen.aliyuncs.com/pdf/Useragreement.pdf',
              numPages: null, // pdf 总页数
          }
      },
      created() {
        console.log(this.$route)
      },
      mounted() {
          this.getNumPages()
      },
      methods: {
        getNumPages() {
          let loadingTask = pdf.createLoadingTask(this.url)
          loadingTask.promise.then(pdf => {
            this.numPages = pdf.numPages
          }).catch(err => {
            console.error('pdf 加载失败', err);
          })
        },
      }
  }
</script>

<style scoped="">

  @media screen and (min-width: 1300px) {
    .user{
      width: 50%;
      margin: 0 auto;
    }
  }
</style>
